import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const defaultTheme = createTheme();

const sansFont = 'Satoshi-Variable';
const serifFont = 'Satoshi-Variable';

function getTypography(textColor, secondaryTextColor) {
  return {
    fontFamily: sansFont,
    fontSize: 14,
    fontWeight: 400,
    h1: {
      fontFamily: serifFont,
      fontWeight: 700,
      color: textColor,
    },
    h2: {
      fontFamily: serifFont,
      fontWeight: 700,
      color: textColor,
    },
    h3: {
      fontFamily: serifFont,
      fontWeight: 700,
      color: textColor,
      fontSize: '36px',
      lineHeight: '44px',
    },
    h4: {
      fontFamily: serifFont,
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '38px',
      color: textColor,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
    h5: {
      fontFamily: serifFont,
      fontWeight: 700,
      fontSize: 24,
      color: textColor,
    },
    h6: {
      fontFamily: serifFont,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '30px',
      color: textColor,
    },
    subtitle1: {
      fontFamily: sansFont,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      color: textColor,
    },
    subtitle2: {
      fontFamily: sansFont,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '18px',
      color: '#141417',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: sansFont,
      marginTop: 5,
    },
    body1: {
      fontSize: 16,
      color: textColor,
    },
    body2: {
      fontSize: 14,
      color: secondaryTextColor,
    },
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
  };
}

const baseTheme = {
  typography: getTypography('#1F2937', '#3F3F46'),
  components: {
    MuiPopper: {
      styleOverrides: {
        root: {
          '.MuiList-root': {
            padding: '4px 0',
          },
          '.MuiMenuItem-root': {
            color: '#3F3F46',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            height: '38px',
            minWidth: '240px',
          },
          '.MuiSvgIcon-root': {
            fontSize: '20px',
            color: '#3F3F46',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
       a {
         color: #374151;
       }
     `,
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: '#F4F4F5',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow:
            '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)!important',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          textDecoration: 'underline',
        },
        underlineHover: {
          textDecoration: 'underline',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow:
            '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
          backgroundColor: '#131316',
          borderRadius: '8px',
          padding: '12px',
          color: '#D1D1D6',
          fontSize: 13,
          fontWeight: 500,
          lineHeight: '18px',
          a: {
            color: 'inherit!important',
            textDecoration: 'underline!important',
          },
        },
        arrow: {
          color: '#131316',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingTop: '18px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingTop: '0!important',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#3F3F46',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          minWidth: '88px',
          padding: '9px 17px',
          height: '38px',
          gap: '4px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
        },
        sizeMedium: {
          minWidth: '88px',
          padding: '10px 17.1px',
          height: '40px',
          gap: '4px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
          '.MuiButton-startIcon': {
            marginRight: '2px',
          },
        },
        sizeLarge: {
          padding: '13px 25px',
          height: '50px',
          fontSize: '16px',
        },
        containedDanger: {
          backgroundColor: '#B42318',
          color: '#FFFFFF',
        },
        containedPrimary: {
          '&:disabled': {
            backgroundColor: '#0063F7',
            opacity: 0.4,
            color: '#FFFFFF',
          },
        },
        containedSecondary: {
          backgroundColor: '#F4F4F5',
          color: '#18181B',
          '&:hover': {
            backgroundColor: '#DfDfDf',
          },
        },
        outlinedSecondary: {
          color: '#3F3F46',
          borderColor: '#D4D4D8',
        },
        containedLight: {
          boxShadow: 'none',
          background: '#F4F4F5',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        textSecondary: {
          background: '#F4F4F5',
        },
        root: {
          borderRadius: '99px',
          boxSizing: 'border-box',
          boxShadow: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 24px 0 24px!important',
          fontFamily: serifFont,
          fontWeight: 500,
          fontSize: 18,
          lineHeight: '24px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          lineHeight: '1em',
        },
        root: {
          color: '#2E2E33',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '20px',
          marginBottom: '7px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          borderRadius: '8px',
          height: '44px',
        },
        select: {
          minHeight: '1.19em',
          padding: '3px 8px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '5px!important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          padding: '5px',
        },
        input: {
          height: '1.19em',
          padding: '7.5px 4px 7.5px 6px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
          '.Mui-disabled &': {
            color: 'transparent',
            background: 'transparent',
            border: '1px solid #1F1F21',
            borderRadius: '24px',
          },
        },
        text: {
          fontSize: '11px',
          '.Mui-disabled &': {
            color: '#26272B',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#26272B',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          lineHeight: '0.75em',
        },
        paperWidthXs: {
          maxWidth: '384px',
        },
        paperWidthSm: {
          maxWidth: '512px',
        },
        paperWidthMd: {
          maxWidth: '560px',
        },
        paperWidthLg: {
          maxWidth: '984px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          border: '1px solid #E4E4E7',
          borderRadius: '12px',
          background: '#FFFFFF',
        },
        elevation: {
          boxShadow: 'none!important',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        outlined: {
          border: '1px solid #E4E4E7',
        },
        grouped: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          '&:not(:first-of-type)': {
            borderRadius: '6px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '&:not(:last-of-type)': {
              borderRadius: 0,
            },
          },
          '&:not(:last-of-type)': {
            borderRadius: '6px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: '22px',
          fontSize: '12px',
        },
        sizeMedium: {
          height: '24px',
          fontSize: '14px',
        },
        filledPrimary: {
          background: '#EFF4FF',
          border: '1px solid #B2CCFF',
          color: '#004EEB',
        },
        filledSuccess: {
          background: '#ECFDF3',
          border: '1px solid #ABEFC6',
          color: '#067647',
        },
        filledSecondary: {
          background: '#FAFAFA',
          border: '1px solid #E4E4E7',
          color: '#3F3F46',
        },
        filledError: {
          background: '#FDF2FA',
          border: '1px solid #FCCEEE',
          color: '#C11574',
        },
        filledInfo: {
          background: '#EFF4FF',
          border: '1px solid #B2CCFF',
          color: '#004EEB',
        },
        label: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 500,
          lineHeight: '1.42rem',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontFamily: serifFont,
          fontWeight: 800,
          fontSize: '12px',
          lineHeight: '18px',
          color: '#51525C',
        },
        cell: {
          paddingTop: '5px',
          paddingBottom: '5px',
          '&:first-child': {
            paddingLeft: '28px',
          },
          '&:last-child': {
            paddingRight: '28px',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        columnHeader: {
          '&:first-child': {
            paddingLeft: '28px',
          },
          '&:last-child': {
            paddingRight: '28px',
          },
        },
        columnHeaderCheckbox: {
          paddingLeft: '7px!important',
        },
        cellContent: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
        },
        cellCheckbox: {
          paddingRight: '20px',
        },
        row: {
          '&.Mui-selected': {
            backgroundColor: '#FAFAFA',
            '&:hover': {
              backgroundColor: '#FDFDFD',
            },
          },
        },
        columnHeadersInner: {
          marginTop: '4px',
          background: 'none',
        },
        columnSeparator: {
          display: 'none',
        },
        root: {
          padding: '0',
          border: '1px solid #E4E4E7',
          borderRadius: '12px',
        },
        columnHeaders: {
          minHeight: '44px!important',
          maxHeight: '44px!important',
          lineHeight: '44px!important',
        },
        footerContainer: {
          minHeight: '53px!important',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#1F2937',
      secondary: '#3F3F46',
    },
    neutral: {
      main: '#D1D5DB',
    },
    secondary: {
      main: '#000000',
    },
    primary: {
      main: '#0063F7',
    },
    light: {
      main: '#F4F4F5',
    },
    danger: {
      main: '#DC2626',
    },
    error: {
      main: '#DC2626',
    },
    background: {
      default: '#fff',
      paper: '#FAFAFA',
    },
  },
};

export const theme = createTheme(baseTheme);
export const darkTheme = createTheme({
  ...baseTheme,
  typography: getTypography('#FFFFFF', '#D4D4D8'),
  components: {
    ...baseTheme.components,
    MuiPaper: {
      styleOverrides: {
        ...baseTheme.components.MuiPaper.styleOverrides,
        rounded: {
          ...baseTheme.components.MuiPaper.styleOverrides.rounded,
          border: '1px solid #27272A',
          background: '#27272A',
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    type: 'dark',
    background: {
      default: '#18181B',
      paper: '#27272A',
    },
  },
});
